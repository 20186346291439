import Vue from 'vue'
import moment from 'moment'
import numeral from 'numeral'
import { format, formatDistance, formatRelative, subDays } from 'date-fns'

Vue.mixin({
    methods: {
        copyToClipboard(value) {
            /**
             * Set text in clipboard.
             */
            let tmp_link = document.createElement("input");
            document.body.appendChild(tmp_link);
            tmp_link.setAttribute("id", "tmp_link");
            document.getElementById("tmp_link").value = value;
            tmp_link.select();
            document.execCommand("copy");
            document.body.removeChild(tmp_link);
            //console.log('Copied LINK to clipboard: ', value);
        },
        numberWithCommas(n, fractionDigits = 2) {
            n = n.toString().replace(/,/g, "");
            let parts = parseFloat(n).toFixed(fractionDigits).toString().split(".");
            return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
        },
        formatCryptoPrice(value) {
            return (value / 1).toFixed(8);
        },
        formatFiatPrice(value) {
            //return (value/1).toFixed(2);
            return this.numberWithCommas(value);
        },
        b64DecodeUnicode(str) {
            // Going backwards: from bytestream, to percent-encoding, to original string.
            return decodeURIComponent(atob(str).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        },
        b64EncodeUnicode(str) {
            // first we use encodeURIComponent to get percent-encoded UTF-8,
            // then we convert the percent encodings into raw bytes which
            // can be fed into btoa.
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
                function toSolidBytes(match, p1) {
                    return String.fromCharCode('0x' + p1);
                }));
        },
        redirectToUrl(redirectUrl, params = [], delay = 3000) {
            if (!redirectUrl || (params && typeof params !== "object")) {
                return;
            }
            if (params && params.length) {
                params = params.join("&");
            } else params = '';

            let url = redirectUrl;
            if (redirectUrl.indexOf('?') > 0) {
                url = url + '&' + params;
            } else {
                url = url + '?' + params;
            }

            window.setTimeout(
                function () {
                    //window.console.debug(`Redirecting to: '${redirectUrl}' with params `, params);
                    window.top.location.href = url;
                },
                delay
            );
        },

        //password between 6 to 20 characters which contain at least one numeric digit,
        // one uppercase and one lowercase letter
        checkPassword(value) {
            let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
            if (value.match(passw)) {
                return true;
            } else {
                return false;
            }
        },
        last5DigitsBankAccountNumber(string) {
            let last5Digits = string.substring(string.length - 5);
            return last5Digits;
        },
        convertDate(string) {
            let date = moment(string);
            let dateComponent = date.local().format('MMM-DD-YYYY');
            let timeComponent = date.local().format('HH:mm:ss');
            return dateComponent + " " + timeComponent;

        },
        removeCommas(value) {
            while (value.search(",") >= 0) {
                value = (value + "").replace(',', '');
            }
            return value;
        },
        formattedAmount(value) {
            //Add the commas back to the string
            // let qty = this.form.qty + ""
            return numeral(value).format('0,0');
            // let n = true
            // while (n) {
            //     var value2 = value.replace(/(\d)(\d{3})($|,|\.)/g, '$1,$2$3')
            //     if (value == value2) break
            //     value = value2
            // }
            // return value2
        },
        isNumeric(value) {

            return /^\d+$/.test(value);

        },

        //Date and time formatting using 'date-fns'
        formatDateForHistoryContent(date)
        {
            return format(new Date(date),'dd MMM yy , p')
        },
        formatDateOnly(date){
            return format(new Date(date), 'dd')
        },
        formatMonthOnly(date){
            return format(new Date(date), 'MMM')
        }
    }


})
;