import axios from '../api/http-axios';
import BigNumber from "bignumber.js";
import _axios from 'axios';

let debugConsole = false;
// let apiDomain = "https://beta.transcryptglobal.com";
let apiDomain = process.env.VUE_APP_STAGING_ENV ? process.env.VUE_APP_API_DOMAIN_TESTING : process.env.VUE_APP_API_DOMAIN;

if(debugConsole) console.log("Api Domain", apiDomain);


const actions = {
    userLogin({commit}, payload) {
       if(debugConsole) console.warn('User login starting....');
        if(debugConsole) console.debug("User Login Data", payload);
        if (!payload) {
            console.warn("No payload for user login");
            return;
        }

        let url = `${apiDomain}/api/v1/login`;

        return axios.post(url, payload).then(res => {
            if(debugConsole) console.log("Login Result", res);
            return res;
        }, err => {
            if(debugConsole) console.log("Error in Login result", err);
            throw err;
        })

    },
    registerUser({commit}, payload) {
        console.warn("Register User Starting.....")
        if(debugConsole) console.debug("user Login Data", payload)

        if (!payload) {
            console.warn("No Payload for Register");
            return;
        }


        let url = `${apiDomain}/api/v1/user`;

        return axios.post(url, payload)
            .then(res => {
                if(debugConsole)  console.log("Register Result", res.data);

                return res;
            }, err => {
                if(debugConsole)  console.log("Error in Register", err);
                throw err;
            })

    },

    submitRemittanceDetails({state, commit}, payload) {
        console.warn("Submit Remittance Details starting.......");
        if(debugConsole) console.debug('Submit remittance details', payload);

        if (!payload) {
            console.warn("No Payload for Remittance Submission");
            return;
        }

        let url = `${apiDomain}/api/v1/tx`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }

        }
        return axios.post(url, payload, headers).then(res => {
            if(debugConsole) console.log("POST submit remittance details ", res);
            return res;
        }, err => {
            if(debugConsole) console.log("POST err submit remittance details", err);
            throw err;
        });
    },

    getTranscryptExchangeRate({commit}, payload) {
        console.warn("Get Transcrypt Exchange Rate starting...");
        if(debugConsole)  console.log("Payload", payload);

        let fromCurrency = payload.fromCurrency;
        let toCurrency = payload.toCurrency;

        let url = `${apiDomain}/api/v1/exchange_rate/${fromCurrency}/${toCurrency}`;

        return axios.get(url);
    },


    getExchangeRate({commit}, payload) {

        let fromCurrency = payload.fromCurrency;
        let toCurrency = payload.toCurrency;

        let url = `https://api.triple-a.io/api/v1/exchange_rate/HA15809758623D0o_t/${fromCurrency}/${toCurrency}`;

        return axios.get(`${url}`)
        //     .then(res => {
        //     console.log("res", res);
        //
        //     // if(res.data.from_currency !== "BTC"){
        //     //     let exchangeRate= new BigNumber(res.data.exchange_rate);
        //     //     commit('UPDATE_FIAT_EXCHANGE_RATE', exchangeRate.toFixed(2))
        //     // }else
        //     // {
        //     //     let exchangeRate= new BigNumber(res.data.exchange_rate);
        //     //     commit("UPDATE_CRYPTO_EXCHANGE_RATE", exchangeRate.toFixed(2));
        //     // }
        // })
        //     .then(result => {
        //     console.log("Result in the API", result);
        //     return result;
        // }).catch(err => {
        //     console.log("Error in the API", err.response);
        //     throw err;
        // })

    },

    getSenderDetails({state, commit}) {
        console.warn("Get Sender Details starting....")

        let url = `${apiDomain}/api/v1/user/details`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }

        }

        return axios.get(url, headers).then(result => {
            if(debugConsole) console.log("Get Sender Details res", result);

            if (result.data) {
                let payload = {}
                payload.senderFirstAndMiddleName = result.data.user_first === null ? "" : result.data.user_first;
                payload.senderLastName = result.data.user_last === null ? "" : result.data.user_last;
                payload.senderDateOfBirth = result.data.user_dob === null ? "" : result.data.user_dob.split('T')[0];
                payload.senderPhone = result.data.user_phone === null ? "" : result.data.user_phone;
                payload.senderCountry = state.userDetails.profile.senderCountry ? state.userDetails.profile.senderCountry : result.data.address_country === null ? result.data.country : result.data.address_country;
                payload.senderCity = result.data.address_city === null ? "" : result.data.address_city;
                payload.senderAddress = result.data.address_lines === null ? "" : result.data.address_lines;
                payload.senderPostalCode = result.data.postal_code === null ? "" : result.data.postal_code;
                payload.senderProvince = result.data.address_state === null ? "" : result.data.address_state;
                payload.senderVerified = result.data.verify_email;
                payload.senderKYCStatus = result.data.kyc_status;
                payload.userStatus = result.data.status;
                if(debugConsole) console.log("Payload to sender/user details", payload);

                commit("UPDATE_USER_PROFILE", payload);
                if(debugConsole) console.log("After committing");
            }


            return result;
        }, err => {
            if(debugConsole) console.log("Get Sender Details Error", err);
            throw err;
        })

    },

    submitSenderDetails({state, commit}, payload) {
        console.warn("Submit Sender Details starting....");
        if(debugConsole) console.debug("Submit Sender Details payload", payload);

        if (!payload) {
            console.warn("No payload for submit sender Details");
            return
        }

        let url = `${apiDomain}/api/v1/user/details`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.put(url, payload, headers).then(res => {
            if(debugConsole)  console.log("Submit Sender Details", res)
            return res;
        }, error => {
            if(debugConsole) console.log("Submit Sender Details error", error)
            throw error;
        })
    },

    createRecipientProfile({state, commit, getters}, payload) {
        console.warn("Create Recipient Starting....");
        if(debugConsole) console.log("Create Recipient Profile payload", payload);

        if (!payload) {
            console.warn('No payload for create recipient')
            return;
        }

        let url = `${apiDomain}/api/v1/receiver`
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.post(url, payload, headers).then(res => {
            if(debugConsole) console.log("Create recipient profile res", res);
            return res;
        }, error => {
            if(debugConsole)  console.log("Create recipient profile error ", error);
            throw error;
        })

    },

    updateRecipientBank({state, commit}, payload) {
        console.warn("Create Recipient Bank starting....");
        if(debugConsole) console.log("Update recipient bank payload", payload);

        if (!payload) {
            console.warn("No payload for create recipient bank");
            return;
        }

        let url = `${apiDomain}/api/v1/receiver/${payload.receiverKey}/bank`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.put(url, payload.bank, headers).then(res => {
            if(debugConsole)  console.log("Update Recipient Bank res", res);
            return res;
        }, error => {
            if(debugConsole) console.log('Update Recipient bank err', error);
            throw error;
        })
    },

    getReceivers({state, commit}) {
        console.warn("Get receivers starting....");

        let url = `${apiDomain}/api/v1/receivers`
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url, headers).then(res => {
            if(debugConsole)  console.log("Get receivers result", res);

            commit("GET_RECIPIENTS", res.data);
            return res;
        }, error => {
            if(debugConsole) console.log('Update receivers bank err', error);
            throw error;
        })
    },

    linkTransactionWithReceiver({state, commit, getters}, payload) {
        console.warn("Link Transaction With Receivers starting....");

        if(debugConsole)  console.log("PaymentDetails Getter", getters.paymentDetailsGetter)

        let txReference = getters.paymentDetailsGetter && getters.paymentDetailsGetter.remittanceInfo && getters.paymentDetailsGetter.remittanceInfo.txReference;
        let receiverKey = payload.receiverKey;

        if(debugConsole) console.log("Tx Reference for link transaction with receivers", txReference);
        if(debugConsole) console.log("Receiver Key for link transaction with receivers", receiverKey);

        let url = `${apiDomain}/api/v1/tx/${txReference}/link`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        let data = {}
        data.rcvr_key = receiverKey;

        return axios.put(url, data, headers).then(res => {
            if(debugConsole) console.log("linking transaction with receiver result", res);
            return res;
        }, err => {
            if(debugConsole) console.log("Error in linking transaction with receiver")
            throw err
        })
    },

    getReviewTransaction({state, getters, commit}, payload) {
        console.warn("Get Review Transaction starting....");
        if(debugConsole) console.log("get review transaction payload", payload);

        if (!payload) {
            console.log("No payload for get review transaction")
            return;
        }

        let url = `${apiDomain}/api/v1/tx/${payload.txReference}`

        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url, headers).then(result => {
            if(debugConsole) console.log("Get Review Transaction res", result);

            if (result.data && result.data.tx) {
                let data = {};
                data.sendAmount = result.data.tx.send_amt;
                data.sendCurrency = result.data.tx.send_ccy;
                data.cryptoAmount = result.data.tx.crypto_amt === 0 ? result.data.tx.indicative_crypto_amt : result.data.tx.crypto_amt;
                data.receiveAmount = result.data.tx.receive_amt;
                data.receiveCurrency = result.data.tx.receive_ccy;
                data.fiatExchangeRate = getters.paymentDetailsGetter.remittanceInfo && getters.paymentDetailsGetter.remittanceInfo.fiatExchangeRate;
                data.cryptoExchangeRate = getters.paymentDetailsGetter.remittanceInfo && getters.paymentDetailsGetter.remittanceInfo.cryptoExchangeRate;
                data.transferFee = result.data.tx.fee;
                data.txReference = result.data.tx.tx_ref_num;
                data.finalSendAmount = result.data.tx.send_amt - result.data.tx.fee;
                data.kycUrl = result.data.tx.url_kyc;
                data.cryptoCurrency = result.data.tx.crypto_ccy;

                if(debugConsole) console.log("Remittance Data for review", data);

                commit('UPDATE_REMITTANCE_INFO', data);
            }

            if (result.data && result.data.recipient) {
                let data = {};
                data.recipientFullName = result.data.recipient.recipient_name;
                data.recipientEmail = result.data.recipient.recipient_email;
                data.recipientBankAccountNumber = result.data.recipient.bank_acc_num;
                data.recipientBankSwiftCode = result.data.recipient.bank_swift;
                data.receiverKey = payload.receiverKey;
                data.recipientBankName = result.data.recipient.bank_name;
                data.recipientBankAccountName = result.data.recipient.bank_acc_name;
                data.recipientBankCountry = result.data.recipient.bank_country;
                data.recipientStatus = result.data.recipient.receiver_status;

                if(debugConsole)  console.log("Recipient data for review", data);

                commit('UPDATE_RECIPIENT_DETAILS', data);

            }


            return result;
        }, error => {
            console.log("Get review transaction error", error);
            throw error;
        })


    },

    getPaymentRequest({state, getters, commit}, payload) {
        console.warn("Payment Request Starting....");

        if (!payload) {
            console.log("No payload for get transaction confirm");
            return;
        }

        let fiatExchangeRate = getters.paymentDetailsGetter && getters.paymentDetailsGetter.remittanceInfo && getters.paymentDetailsGetter.remittanceInfo.fiatExchangeRate;

        let url = `${apiDomain}/api/v1/tx/${payload.txReference}/confirm`;

        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.put(url, null, headers).then(res => {
            if(debugConsole) console.log("Payment Request result", res);

            if (res.data) {
                let data = {};
                data.txReference = res.data.tx_ref_num;
                data.sendAmount = res.data.send_amt;
                data.sendCurrency = res.data.send_ccy;
                data.transferFee = res.data.fee;
                data.receiveAmount = res.data.receive_amt;
                data.receiveCurrency = res.data.receive_ccy;
                data.cryptoExchangeRate = res.data.crypto_exchange_rate;
                data.cryptoAmount = res.data.crypto_amount;
                data.cryptoCurrency = res.data.crypto_ccy;
                data.fiatExchangeRate = fiatExchangeRate;
                data.finalSendAmount = res.data.send_amt - res.data.fee;
                data.hostedURL = res.data.hosted_url;
                data.tx_status = res.data.tx_status;
                // convert sats value to btc
                if(data.cryptoCurrency === "LNBC" && parseInt(data.cryptoAmount) === data.cryptoAmount) {
                    data.cryptoAmount /= 1e8;
                }

                commit("UPDATE_PAYMENT_REQUEST", data);

                //to prepopulate the remittance form
                let dataForRemittanceForm = {}

                dataForRemittanceForm.sendAmount = res.data.send_amt;
                dataForRemittanceForm.sendCurrency = res.data.send_ccy;
                dataForRemittanceForm.cryptoAmount = res.data.crypto_amount;
                dataForRemittanceForm.receiveAmount = res.data.receive_amt;
                dataForRemittanceForm.receiveCurrency = res.data.receive_ccy;
                dataForRemittanceForm.fiatExchangeRate = fiatExchangeRate;
                dataForRemittanceForm.transferFee = res.data.fee;
                dataForRemittanceForm.txReference = res.data.tx_ref_num;
                dataForRemittanceForm.finalSendAmount = res.data.send_amt - res.data.fee;
                dataForRemittanceForm.cryptoCurrency = res.data.crypto_ccy;
                if(res.data.crypto_exchange_rate !== "")
                {
                    dataForRemittanceForm.cryptoExchangeRate = res.data.crypto_exchange_rate;
                }else
                {
                    dataForRemittanceForm.cryptoExchangeRate = state.paymentDetails.remittanceInfo.cryptoExchangeRate;
                }
                // convert sats value to btc
                if(dataForRemittanceForm.cryptoCurrency === "LNBC" && parseInt(dataForRemittanceForm.cryptoAmount) === dataForRemittanceForm.cryptoAmount) {
                    dataForRemittanceForm.cryptoAmount /= 1e8;
                }
               commit("UPDATE_REMITTANCE_INFO", dataForRemittanceForm);
            }

            return res;
        }, error => {
            if(debugConsole)console.log('Payment Request error', error);
            throw error;
        })


    },
    getTransactions({state, commit}) {
        console.warn("Get Transaction History starting....");

        let url = `${apiDomain}/api/v1/tx/history`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url, headers).then(res => {
            if(debugConsole)console.log("Get transaction history result", res);

            commit("UPDATE_TRANSACTIONS", res.data);

            return res;
        }, error => {
            if(debugConsole) console.log("Get transaction history error", error);
        })


    },

    changePassword({state},payload) {
        console.warn("Change password starting....")

        if (!payload) {
            if(debugConsole)  console.log("Payload for change password does not exist");
            return;
        }

        let url = `${apiDomain}/api/v1/user/password/change`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }


        return axios.put(url, payload,headers).then(res => {
            if(debugConsole)console.log("Password change result", res);
            state.userDetails.userToken = res.data.jwt;
            return res;
        },err=> {
            if(debugConsole) console.log("Password change err", err);
            throw err;
        })


    },

    contactSupport({state},payload){
        console.warn ("change password Setting");

        if(!payload){
            console.log("payload for contact support does not exist");
            return
        }

        let url = `${apiDomain}/api/v1/support`;
        return axios.post(url,payload).then(res => {
            if(debugConsole) console.log("Contacting support res", res);
            return res;
        },err => {
            if(debugConsole)console.log("Error in contacting support", err);
            throw err;
        })

    },
    resetPassword({state}, payload){
        console.warn("Reset password starting");

        if(!payload){
            console.log("Payload for reset password does not exist");
            return;
        }

        let url = `${apiDomain}/api/v1/user/password/reset`;
        return axios.post(url, payload).then(res => {
            if(debugConsole)  console.log("Password reset result", res);
            return res;
        },err => {
            if(debugConsole) console.log("Error in reset password", err);
            throw err;
        })
    },

    calculation({state},payload){
      console.warn('Calculation starting....');

      if(!payload)
      {
          console.log("Payload for calculation does not exist");
          return;
      }

      let url = `${apiDomain}/api/v1/calculate`;
      return axios.post(url, payload).then(res => {
          if (debugConsole) console.log("Calculation Result", res);
          return res;
      },err => {
          throw err;
      })
    },


    submitUserProfile({commit}, payload) {

        commit('UPDATE_USER_PROFILE', payload);

    },

    submitSelfBankDetails({commit}, payload) {

        commit('UPDATE_USER_BANK_DETAILS', payload);
    },
    submitRecipientProfileAndBankDetails({commit}, payload) {

        commit("UPDATE_RECIPIENT_PROFILE_AND_BANK_DETAILS", payload);
    },
    submitTransferType({commit}, payload)
    {
        // console.debug("Payload for transfer type", payload);
        commit("UPDATE_TRANSFER_TYPE", payload);
    },


// ==================================TRADING================================

    tradingUserLogin({commit}, payload) {
        if(debugConsole) console.warn('User login starting....');
        if(debugConsole) console.debug("User Login Data", payload);
        if (!payload) {
            console.warn("No payload for user login");
            return;
        }

        let url = `${apiDomain}/api/v1/login`;

        return axios.post(url, payload).then(res => {
            if(debugConsole) console.log("Login Result", res);
            return res;
        }, err => {
            if(debugConsole) console.log("Error in Login result", err);
            throw err;
        })

    },

    // separate sign up API just for trading, but account will still be able to be used for TransCrypt cash out.
    tradingRegisterUser({commit}, payload) {
        console.warn("Register User Starting.....")
        if(debugConsole) console.debug("user Login Data", payload)

        if (!payload) {
            console.warn("No Payload for Register");
            return;
        }


        let url = `${apiDomain}/api/v1/trading/user`;

        return axios.post(url, payload)
            .then(res => {
                if(debugConsole)  console.log("Register Result", res.data);

                return res;
            }, err => {
                if(debugConsole)  console.log("Error in Register", err);
                throw err;
            })

    },

    // buyCryptoAction({state,commit}, payload){
    //     console.warn ('Buy crypto starting.....');

    //     if(!payload)
    //     {
    //         console.log("Payload does not exist in buy crypto")
    //     }

    //     let url = `${apiDomain}/api/v1/trading/buy`;
    //     const headers = {
    //         headers: {
    //             Authorization: `Bearer ${state.userDetails.userToken}`
    //         }
    //     }
    //     return axios.post(url, payload, headers).then(res => {
    //         return res;
    //     }, error => {
    //         throw error;
    //     })
    // },

    callExchangeRateAction({state,commit}, payload)
    {
        console.warn('Call new exchange rate starting.....');

        if(!payload)
        {
            console.log("Payload does not exist when calling the new exc rate");
        }

        let url = `${apiDomain}/api/v1/trading/lock-rate`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }
        return axios.post(url, payload, headers).then(res => {
            return res;
        },error => {
            throw error;
        })
    },
    getUserBalances({state,commit})
    {
        console.warn('Get User Balances.....');

        let url = `${apiDomain}/api/v1/trading/balances`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url,headers).then(res => {
            if(debugConsole)   console.log("Get User Balances Res", res.data);

            commit("UPDATE_USER_BALANCES", res.data);
            return res;

        },err => {
            console.log("Get user Balances Err", err);
            throw err;
        })

    },

    getTradeDetails({state,commit}, payload)
    {
        console.warn("Get trade details start.....");
        let tradeId = payload;

        let url = `${apiDomain}/api/v1/trading/trades/${tradeId}`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url, headers).then(res => {
            if(debugConsole)  console.log("Get trade detail result",res)
            return res;
        },err=> {
            throw err;
        })
    },

    sellCryptoAction({state,commit}, payload)
    {
        console.warn("Sell Crypto Action starts.....");

        if(!payload)
        {
            console.log("Payload does not exist in sell crypto");
        }

        let url = `${apiDomain}/api/v1/trading/sell`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.post(url, payload, headers).then(res => {
            if(debugConsole)  console.log("Result of selling crypto", res.data);
            return res;
        },err => {
            console.log("Err in selling crypto", err);
            throw err;
        })

    },

    redeemCodeAction({state,commit},payload)
    {
        console.warn("Redeem Voucher starting...");
        if(!payload)
        {
            console.log('Payload does not exist in the redeem code');
        }

        let url = `${apiDomain}/api/v1/trading/redeem`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`,
                'Content-Type': 'application/json',
            }
        }

        return axios.put(url, payload, headers).then(res => {
            if(debugConsole)  console.log("Result of redeeming voucher", res.data);
            return res;
        },err => {
            console.log("Err in redeeming voucher", err);
            throw err;
        })


    },

    getTransactionHistoryAction({state,commit}, payload){
        console.warn("Get transaction history...");
        // if(!payload)
        // {
        //     console.log('Payload does not exist in the get transaction history');
        // }
        let queryString;
        if(!payload)
        {
            //use the default params as the query string
            queryString = `limit=8&offset=0&desc=true`
        }else
        {
            queryString = payload.queryString;
        }

        let url = `${apiDomain}/api/v1/trading/transactions?${queryString}`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url, headers).then(res => {
            if(debugConsole)  console.log("Result of transaction History", res.data);

            commit("UPDATE_USER_TRANSACTION_HISTORY", res.data);
            return res;
        },err => {
            console.log("Err in getting transaction history", err);
            throw err;
        })

    },

    withdrawToBankAccountAction({state,commit}, payload)
    {
        console.warn("Get WithdrawToBankAccountAction starting....")

        if(!payload)
        {
            console.log("Payload does not exist in the get transaction history");
        }

        let url = `${apiDomain}/api/v1/trading/withdraw`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.post(url, payload, headers).then(res => {
            if(debugConsole)  console.log("Result of withdraw to bank account", res.data);

            commit("UPDATE_USER_WITHDRAW_TO_BANK_ACCOUNT_DETAIL", res.data);
            return res;
        },err => {
            console.log("Err in withdrawing to bank account", err);
            throw err;
        })

    },
    getAuthenteqUrl({state,commit}){
        console.warn("Get Authenteq URL starting....")


        let url = `${apiDomain}/api/v1/trading/withdraw/verify`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url, headers).then(res => {
            if(debugConsole)  console.log("Result of authenteq URL", res.data);

            commit("UPDATE_KYC_URL", res.data.url_kyc);
            return res;
        },err => {
            console.log("Err in getting Authenteq URL", err);
            throw err;
        })

    },

    getPriceFeed({state,commit}, payload)
    {
        console.warn("get price feed starting....");

        // let url = `${apiDomain}/api/v1/trading/price-feed?ccy=BTC&duration=HOUR`

        //parameter requirements:
        // ccy: BTC | ETH | USDT
        // duration: HOUR (every 1 minute) | DAY (every 10 minutes) | WEEK (every 1 hour) | MONTH (every 1 hour) | YEAR (every day)

        let currency = payload.ccy;
        let duration = payload.duration;

        let url = `${apiDomain}/api/v1/trading/price-feed?ccy=${currency}&duration=${duration}`
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url, headers).then(res => {
            if(debugConsole)   console.log(`Result of price feed of ${currency} and duration of ${duration}`, res.data);

            //store in vuex state depends on the duration and currency
            let val = {};
            val.duration= duration;
            val.result = res.data.sort(function (a, b) {
                return new Date(b.rt_dt) - new Date(a.rt_dt);
            });

            if(debugConsole)  console.log("Val data", val);
            commit("UPDATE_PRICE_FEED", val);

            return res;

        },err => {
            console.log(`Error in getting price feed of ${currency} and duration of ${duration}`, err);
            throw err;
        })

    },

    getPriceFeedNew({state,commit}, payload)
    {
        console.warn("get price feed new starting....");

        // let url = `${apiDomain}/api/v1/trading/price-feed?ccy=BTC&duration=HOUR`

        //parameter requirements:
        // ccy: BTC | ETH | USDT
        // duration: HOUR (every 1 minute) | DAY (every 10 minutes) | WEEK (every 1 hour) | MONTH (every 1 hour) | YEAR (every day)

        let currency = payload.ccy;
        let duration = payload.duration;

        let url = `${apiDomain}/api/v1/trading/price-feed?ccy=${currency}&duration=${duration}`
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url, headers).then(res => {
            if(debugConsole)  console.log(`Result of new price feed of ${currency} and duration of ${duration}`, res.data);

            //store in vuex state depends on the duration and currency
            let val = {};
            val.crypto = currency.toLowerCase();
            val.duration = duration;
            val.result = res.data.sort(function (a, b) {
                return new Date(b.rt_dt) - new Date(a.rt_dt);
            });

            if(debugConsole)   console.log("Val data", val);
            commit("UPDATE_NEW_PRICE_FEED", val);

            return res;

        },err => {
            console.log(`Error in getting new price feed of ${currency} and duration of ${duration}`, err);
            throw err;
        })

    },



    getPriceFeedForHomepage(){
        console.warn("get price feed for homepage starting....");

        let url = `${apiDomain}/api/v1/trading/price-change`;

        return axios.get(url).then(res => {
            if(debugConsole)  console.log("Get price feed for homepage", res.data);
            return res;
        }).catch(err => {
            console.log("Error in getting price feed for homepage", err.response);
            throw err;
        })

    },

    authLogout({state, commit})
    {
        console.warn('logout the auth');

        let url = `${apiDomain}/api/v1/user/logout`;
        const headers = {
            headers: {
                Authorization: `Bearer ${state.userDetails.userToken}`
            }
        }

        return axios.get(url,headers).then(res => {
            if(debugConsole)   console.log("logout response Res", res.data);

            return res;

        },err => {
            console.log("Err in logout the auth", err);
            throw err;
        })

    },

    // registerForTrading({commit}, payload) {
    //     console.warn("Register Trading User Starting.....");
    //     if(debugConsole) console.debug("User Login Data", payload);
    //
    //     if (!payload) {
    //         console.warn("No Payload for Trading User Register");
    //         return;
    //     }
    //
    //
    //     let url = `${apiDomain}/api/v1/trading/user`;
    //
    //     return axios.post(url, payload)
    //         .then(res => {
    //             if(debugConsole)  console.log("Register Trading User Result", res.data);
    //
    //             return res;
    //         }, err => {
    //             if(debugConsole)  console.log("Error in Register Trading User", err);
    //             throw err;
    //         });
    //
    // },


}

export default actions;