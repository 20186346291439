import store from '../store/store';
import router from '../router';

import _axios from 'axios';

const axios = _axios.create();

let debugConsole = false;

axios.interceptors.request.use((request) => {
    if (debugConsole) console.log("What is request", request);
    let originalRequest = request;
    return originalRequest;
}, (err) => {
    return Promise.reject(err);
});


axios.interceptors.response.use(response => {
        if (debugConsole) console.log("What is response", response);
        return response
    },
    (error) => {
        if (debugConsole) console.log("Response Error Config", error.config);
        if (debugConsole) console.log("Response Error ", error.response);
        // return new Promise((resolve, reject) => {
        //     reject(error);
        // });

        let url = new URL(window.location);

        //TODO will need to remove "/trading" after TransCrypt trading is done.
        //list of pages that does not need access token
        let pages = ['/account/register', '/support', '/home', '/send-money', '/trading-home', '/trading/user/register'];
        let urlPathName = url.pathname;

        let noTokenRequired = pages.some(el => urlPathName.includes(el))
        if (debugConsole) console.log("No token required", noTokenRequired);

        if (error.response.data.message === 'not_authorized' || (!store.state.userDetails.userToken && !noTokenRequired)){
            if (debugConsole) console.log("user token doesn't exists");

            if(store.state.transferType === 'trading')
            {
                console.log("Back to login trading page")
                store.commit('USER_LOGOUT');

                router.push('/trading/user/login').catch(() => {});

            }else {
                console.log("Back to login cashout/send-money page")
                store.commit('USER_LOGOUT');

                router.push('/account/login').catch(() => {});

            }

            // return new Promise((resolve, reject) => {
            //     reject(error);
            // });
            throw error;
        }
        throw error;

    })


export default axios;
