import Vue from 'vue'
import App from './App.vue'


//Vuex Store
import store from './store/store'

//Vue-Router
import router from './router'

//VueMeta
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

//styling
// import './assets/css/bootstrap.min.css'
// import './assets/css/owl.carousel.css'
// import './assets/css/owl.transitions.css'
// import './assets/css/animate.css'
// import './assets/css/meanmenu.min.css'
// import './assets/css/nice-select.css'
// import './assets/css/font-awesome.min.css'
// import './assets/css/themify-icons.css'
// import './assets/css/flaticon.css'
// import './assets/css/magnific.min.css'
// import './assets/css/style.css'
// import './assets/css/homepage-style.css'
// import './assets/css/responsive.css'
// import './assets/css/transcrypt.style.css'


import './helpers/mixinCommonMethods';
import './api/remittance-api';

//Vue Google Tag Manager
import VueGtm from "vue-gtm";

let url = new URL (window.location);
// console.log("localhost", url.host.indexOf('localhost'))
// console.log("beta", url.host.indexOf('beta.transcryptglobal.com'))
if(url.host.indexOf('localhost') < 0 && url.host.indexOf('beta.transcryptglobal.com') < 0)
{
  Vue.use(VueGtm,{
    id :"GTM-T5WV53B",
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true,
    vueRouter: router,
  })
}



import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast)


import Cryptoicon from 'vue-cryptoicon';
import icons from 'vue-cryptoicon/src/icons';
Cryptoicon.add(icons);
Vue.use(Cryptoicon);



import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);




Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
