import Vue from 'vue'
import axios from './http-axios'; // Use if need for HTTP header customisations

// let url = new URL(window.location);
// let type = url.href.indexOf('8080') > 0 ? 'hey, I got you' : 'No';
// window.console.log("Type", type);

let apiDomain = "https://api.triple-a.io/api";

Vue.mixin({
    methods: {
        /**
         * GET payment_form_session
         *
         * Loads data from the API to display the payment form
         */

        // getPaymentToken() {
        //     let url = `${apiDomain}/v1/oauth/token`
        //
        //     //currently hardcoded using "merchant" for crypto remit
        //     let clientId = 'oacid-ckijmeoq90w33yunse22q41gk';
        //     let clientSecret = '740534b9a04ebf8002fa9c5f4d7480d838eec85d12b26008245f324c7bceb92a';
        //
        //     const params = new URLSearchParams();
        //     params.append('client_id', clientId);
        //     params.append('client_secret', clientSecret)
        //     params.append('grant_type', 'client_credentials');
        //
        //     return axios.post(url, params, {
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded'
        //         }
        //     })
        //
        // },


        // getExchangeRate(fromCurrency, toCurrency) {
        //
        //     let url = `${apiDomain}/v1/exchange_rate/HA15809758623D0o_t/${fromCurrency}/${toCurrency}`;
        //
        //     return axios.get(`${url}`)
        //     //     .then(result => {
        //     //     console.log("Result in the API", result);
        //     //     return result;
        //     // }).catch(err => {
        //     //     console.log("Error in the API", err.response);
        //     //     throw err;
        //     // })
        //
        // },

        // createPayment(paymentData, paymentToken) {
        //     let url = `${apiDomain}/v1/payment/request`
        //
        //     return axios.post(url, paymentData, {
        //         headers: {
        //             'Authorization': `Bearer ${paymentToken}`
        //         }
        //     })
        //
        // },

        // payment_form_session(payment_reference, access_token) {
        //     let formSessionUrl = `${apiDomain}/v1/payment/${payment_reference}?access_token=${access_token}`;
        //     return axios.get(
        //         `${formSessionUrl}`
        //     );
        // },





    } // /methods
});
