import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/account',
            name: 'AccountLayout',
            component: () => import('./layouts/AccountLayout'),
            children: [
                {
                    path: 'login',
                    component: () => import('./components/AccountLogin'),
                    // meta: {
                    //     title: 'TransCrypt - Login | Send Money Faster with Cryptocurrency'
                    // }
                },
                {
                    path: 'register',
                    component: () => import('./components/AccountRegister'),
                    // meta: {
                    //     title: 'TransCrypt - Register Account | Send Money Faster with Cryptocurrency'
                    // }
                },
                {
                    path: 'confirm-email',
                    component: () => import ('./components/AccountConfirmEmail'),
                    // meta: {
                    //     title: 'TransCrypt - Confirm Email | Send Money Faster with Cryptocurrency'
                    // }
                },
                {
                    path: 'logout',
                    component: () => import('./components/AccountConfirmLogout'),
                    // meta: {
                    //     title: 'TransCrypt - Logout | Send Money Faster with Cryptocurrency'
                    // }
                }
            ],
            redirect: '/account/login',
        },
        {
            path: '/transfer',
            name: 'TransferLayout',
            component: () => import('./layouts/TransferLayout'),
            children: [
                {
                    path: 'remittance-form',
                    component: () => import('./components/TransferRemittanceForm'),
                    // meta: {
                    //     title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency'
                    // }

                },
                {
                    path: 'sender-details',
                    component: () => import('./components/TransferSenderDetails'),
                    beforeEnter: (to, from, next) => {
                        if (to.fullPath === "/transfer/sender-details" && from.fullPath === "/") {
                            next({path: '/transfer'});
                        } else {
                            next();
                        }
                    },
                    // meta: {
                    //     title: 'TransCrypt - Sender Details | Send Money Faster with Cryptocurrency'
                    // }

                },
                {
                    path: 'recipient-details',
                    component: () => import('./components/TransferRecipientDetails'),
                    beforeEnter: (to, from, next) => {
                        if (to.fullPath === "/transfer/recipient-details" && from.fullPath === "/") {
                            next({path: '/transfer'});
                        } else {
                            next();
                        }
                    },
                    // meta: {
                    //     title: 'TransCrypt - Recipient Details | Send Money Faster with Cryptocurrency'
                    // }

                },
                {
                    path: 'review-details',
                    component: () => import('./components/TransferReviewDetails'),
                    // beforeEnter: (to, from, next) => {
                    //     if (to.fullPath === "/transfer/review-details" && from.fullPath === "/") {
                    //         next({path: '/transfer'});
                    //     } else {
                    //         next();
                    //     }
                    // }
                    // meta: {
                    //     title: 'TransCrypt - Review Details | Send Money Faster with Cryptocurrency'
                    // }

                },
                {
                    path: 'kyc-details',
                    component: () => import('./components/TransferKYCDetails'),
                    beforeEnter: (to, from, next) => {
                        if (to.fullPath === "/transfer/kyc-details" && from.fullPath === "/") {
                            next({path: '/transfer'});
                        } else {
                            next();
                        }
                    }

                },
                {
                    path: 'make-payment',
                    component: () => import('./components/TransferMakePayment'),
                    // beforeEnter: (to, from, next) => {
                    //     if (to.fullPath === "/transfer/make-payment" && from.fullPath === "/") {
                    //         next({path: '/transfer'});
                    //     } else {
                    //         next();
                    //     }
                    // }
                    // meta: {
                    //     title: 'TransCrypt - Make Payment | Send Money Faster with Cryptocurrency'
                    // }

                },

            ],
            redirect: '/transfer/remittance-form',
        },
        {
            path: '/dashboard',
            name: 'DashboardLayout',
            component: () => import('./layouts/DashboardLayout'),
            children: [
                {
                    path: '',
                    component: () => import('./components/DashboardHome'),
                    // meta: {
                    //     title: 'TransCrypt - Dashboard Home | Send Money Faster with Cryptocurrency'
                    // }
                },
                {
                    path: 'settings',
                    component: () => import('./components/DashboardSettings'),
                    // meta: {
                    //     title: 'TransCrypt - Dashboard Settings | Send Money Faster with Cryptocurrency'
                    // }
                },
                {
                    path: 'recipients',
                    component: () => import('./components/DashboardRecipients'),
                    // meta: {
                    //     title: 'TransCrypt - Dashboard Recipients | Send Money Faster with Cryptocurrency'
                    // }
                },
                {
                    path: 'transfer-details',
                    component: () => import('./components/DashboardTransferDetails'),

                },
            ]
        },
        {
            path: '/recipient',
            name: 'RecipientDetailLayout',
            component: () => import('./layouts/RecipientDetailLayout.vue'),
            children: [
                {
                    path: 'bank-details-unknown',
                    component: () => import ('./components/RecipientDetailComponent'),
                }
            ]
        },
        {
            path: '/home',
            name: 'Homepage',
            component: () => import('./components/SiteHomepage'),
            // meta: {
            //     title: 'TransCrypt | Send Money with Crypto | Borderless Transfer',
            //     metaTags:
            //         {
            //             name: 'home-page',
            //             content: 'Send money with cryptocurrency to your loved ones or yourself. Borderless transfer to 120+ countries. Quick sign up, low transfer fee, anytime anywhere, 24/7.'
            //         },
            //
            // }
        },
        {
            path: '/send-money',
            name: 'HomepageMoneyTransfer',
            component: () => import('./components/SiteHomepageMoneyTransfer'),
            // meta: {
            //     title: 'TransCrypt | Send Money with Crypto | Borderless Transfer',
            //     metaTags:
            //         {
            //             name: 'home-page',
            //             content: 'Send money with cryptocurrency to your loved ones or yourself. Borderless transfer to 120+ countries. Quick sign up, low transfer fee, anytime anywhere, 24/7.'
            //         },
            //
            // }
        },
        {
            path: '/about',
            name: 'AboutPage',
            component: () => import('./components/SiteAbout'),
            // meta: {
            //     title: 'About TransCrypt | Money Transfer with Cryptocurrency',
            //     metaTags:
            //         {
            //             name: 'about-page',
            //             content: 'Quick, Easy, and Seamless, TransCrypt is a one-stop cryptocurrency remittance service for people to make borderless money transfer with cryptocurrency.'
            //         },
            //
            // }
        },
        {
            path: '/support',
            name: 'SupportPage',
            component: () => import('./components/SiteSupport'),
            // meta: {
            //     title: ' Contact TransCrypt | Send Money Faster with Cryptocurrency',
            //     metaTags:
            //         {
            //             name: 'support-page',
            //             content: 'Money transfer with cryptocurrency wherever and whenever with no limitations. Contact us at support@transcryptglobal.com for more information.'
            //         },
            //
            // }
        },
        {
            path: '/privacy-and-terms-policy',
            name: 'PrivacyAndPolicyPage',
            component: () => import('./components/SitePrivacyAndPolicy'),
            meta: {
                title: ' Privacy and Policy of TransCrypt | Send Money Faster with Cryptocurrency'
            }
        },
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '*',
            component: () => import ('./components/Error404')
        },
        {
            path: '/error-404',
            component: () => import ('./components/Error404')
        },
        //Trading pages
        // {
        //     path: '/trading-home',
        //     name: 'TradingHomepage',
        //     component: () => import('./components/trading/SiteTradingLandingPage.vue'),

        // },
        {
            path: '/trading-special-promotions',
            name: 'TradingProfilePage',
            component: () => import('./components/trading/SiteTradingPromotion'),
        },
        {
            path: '/trading/user',
            component: () => import('./layouts/trading/TradingCredentialLayout.vue'),
            children: [
                {
                    path: 'login',
                    name: 'TradingLoginPage',
                    component: () => import('./components/trading/TradingLogin'),
                },
                {
                    path: 'register',
                    name: 'TradingRegisterPage',
                    component: () => import ('./components/trading/TradingRegister'),
                },
                {
                    path: 'logout',
                    name: 'TradingLogoutPage',
                    component: () => import ('./components/trading/TradingAccountConfirmLogout'),
                }
            ]
        },
        {
            path: '/trading',
            name: 'TradingLayout',
            component: () => import('./layouts/trading/TradingLayout'),
            children :[
                {
                    path: '/trading/main',
                    name: 'TradingMainPage',
                    component: () => import('./components/trading/TradingMainPage.vue'),

                },
                // {
                //     path: '/trading/buy/:cryptocurrency',
                //     name: 'TradingBuyPage',
                //     component: () => import('./components/trading/TradingBuyCrypto.vue'),

                // },
                {
                    path: '/trading/sell/:cryptocurrency',
                    name: 'TradingSellPage',
                    component: () => import('./components/trading/TradingSellCrypto.vue'),


                },
                // {
                //     path: '/trading/notification/buy',
                //     name: 'TradingNotificationBuyPage',
                //     component: () => import('./components/trading/TradingBuyCryptoNotification.vue'),


                // },
                {
                    path: '/trading/notification/sell',
                    name: 'TradingNotificationSellPage',
                    component: () => import('./components/trading/TradingSellCryptoNotification.vue'),


                },
                {
                    path: '/trading/details/:cryptocurrency',
                    name: 'TradingCurrenciesPage',
                    component: () => import('./components/trading/TradingIndividualCrypto.vue'),
                    // children: [
                    //     {
                    //         path: 'bitcoin',
                    //         name: 'TradingCurrenciesPageBitcoin',
                    //         component: () => import ('./components/trading/TradingIndividualCrypto.vue'),
                    //     }
                    // ]
                },
                {
                    path: '/trading/deposit',
                    name: 'TradingDepositBalance',
                    component: () => import ('./components/trading/TradingDepositBalance.vue'),
                },
                {
                    path: '/trading/withdraw',
                    name: 'TradingWithdrawBalance',
                    component: () => import ('./components/trading/TradingWithdrawBalance.vue'),
                },
                {
                    path: '/trading/withdraw/bank-account',
                    name: 'TradingWithdrawBankAccount',
                    component: () => import ('./components/trading/TradingWithdrawBankAccount.vue'),
                },

                {
                    path: '/trading/withdraw/notification',
                    name: 'TradingWithdrawNotification',
                    component: () => import ('./components/trading/TradingWithdrawNotification.vue'),
                },

                {
                    path: '/trading/settings',
                    name: 'TradingSettingsPage',
                    component: () => import('./components/trading/TradingSettingsPage'),
                },
                {
                    path: '/trading/history',
                    name: 'TradingHistoryPage',
                    component: () => import('./components/trading/TradingHistoryPage'),
                },
                {
                    path: '/trading/redeem-voucher',
                    name: 'TradingRedeemVoucher',
                    component: () => import('./components/trading/TradingRedeemVoucher'),
                },
                {
                    path: '/trading/profile',
                    name: 'TradingProfilePage',
                    component: () => import('./components/trading/TradingProfilePage'),
                },
            ],
            redirect: '/trading/main',
        },
    ],

})

router.beforeEach((to, from, next) => {
    // window.console.debug('beforeEach():from: ', from.fullPath);
    // // window.console.debug('beforeEach():to: ', to);
    // window.console.debug('beforeEach():to: ', to.fullPath);
    // console.debug("To", to);
    // console.debug("from", from);
    // console.debug("Store ", store && store.state && store.state.userDetails && store.state.userDetails.userToken)

    //Page Title and Description
    // if(to.meta && to.meta.title)
    // {
    //     document.title = to.meta.title;
    // }
    // else {
    //     document.title = "TransCrypt Global | Send money with cryptocurrency"
    // }

    //pages where you need access token to access
    const publicPages = ['/dashboard', '/transfer', 'account/logout','/trading/main','/trading/buy','/trading/sell','/trading/history','/trading/withdraw','/trading/notification', '/trading/profile', '/trading/settings','/trading/user/logout']

    let routingTo = to.fullPath;
    const authRequired = publicPages.some(el => routingTo.includes(el));
    console.log("Auth Required", authRequired);

    const loggedIn = store.state.userDetails && store.state.userDetails.userToken.length > 0;

    const transferType = store.state.transferType

    // console.log("AuthRequired", authRequired)
    // console.log("Logged In", loggedIn);

    if (authRequired && !loggedIn) {
        if(transferType !== 'trading')
        {
            if(to.fullPath.startsWith('/trading/main'))
            {
                next('/trading/user/login');
            }else {
                next('/account/login');
            }
        } else
        {
            next('/trading/user/login');
        }
    } else if (loggedIn && to.fullPath.startsWith('/account/login')) {
        next('/transfer/remittance-form')
    }else if (loggedIn && to.fullPath.startsWith('/trading/user/login')){
        next('/trading/main')
    }
    else {
        next();
    }

    const reviewPage = '/review-details';

    if((to.fullPath.startsWith('/transfer/review-details') || to.fullPath.startsWith('/trading/withdraw')) && window.localStorage.getItem('visited_authenteq'))
    {
        // console.log("It is review page")
    }else
    {
        // console.log("It is not review Page");
        window.localStorage.removeItem('visited_authenteq');
    }

    // if (!(store.state.userDetails && store.state.userDetails.userToken) && !to.fullPath.startsWith('/account/login')) {
    //     console.log("user token doesn't exist and not in login page");
    //     next('/account/login');
    // }
    // else if (store.state.userDetails.userToken && to.fullPath.startsWith('/account/login')) {
    //     console.log("User token exists")
    //     next('/transfer/remittance-form')
    // } else {
    //     next();
    // }
    // next()
})

export default router;

