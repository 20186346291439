const state = {
    transferType: "",
    userKyc: "",
    userStatus: '',
    stepperState: [
        {
            active: false,
            done: false,
            index: 0,
            stepName: "remittance-info",
        },
        {
            active: false,
            done: false,
            index: 1,
            stepName: "sender-info",
        },
        {
            active: false,
            done: false,
            index: 0,
            stepName: "recipient-info",
        },
        {
            active: false,
            done: false,
            index: 0,
            stepName: "review-info",
        },
        {
            active: false,
            done: false,
            index: 0,
            stepName: "payment-info",
        },
    ],
    paymentDetails: {
        remittanceInfo: {},
        recipientInfo: {},
        paymentRequestInfo: {},
    },
    userDetails: {
        userToken: "",
        userKey: "",
        email: "",
        profile: {
            senderFirstAndMiddleName: "",
            senderLastName: "",
            senderDateOfBirth: "",
            senderPhone: "",
            senderCountry: "",
            senderCity: "",
            senderAddress: "",
            senderPostalCode: "",
        },
        bankDetails: {},
        transactions: [],
        recipients: [],
        poi: {},

        //for Trading (email, poi status will follow the above properties)
        trading: {
            userTransactionHistory: {},
            userBankAccountDetails: [],
            userTradingProfile: [],
            userTradingBalances: [],
            userWithdrawBankAccountDetail: null,
            kycUrl: "",


        }

    },
    recipientDetails: [],
    queryString: null,

    //State for Trading Transcrypt

    // data for trading (crypto price trend, list of crypto available to trade)
    tradingPriceData: {
        hour: [],
        day: [],
        week: [],
        month: [],
        year: []
    },
    newTradingPriceData:
        {
            'btc': {
                hour: [],
                day: [],
                week: [],
                month: [],
                year: []

            },
            'eth':{
                hour: [],
                day: [],
                week: [],
                month: [],
                year: []

            },
            'usdt':{
                hour: [],
                day: [],
                week: [],
                month: [],
                year: []
            },
            'usdc':{
                hour: [],
                day: [],
                week: [],
                month: [],
                year: []
            }
        },
    //not used
    tradingDetails: {
        buyInfo: {
            //buyCryptoamount
            //cryptoCurrency
            //fiatCurrency
            //buyFiatAmount
            //buyExchangeRate
        },
        sellInfo: {
            //sellcryptoamount
            //cryptocurrency
            //fiatcurrency
            //sellfiatamount
            //sellexchangerate
        },

    }


}

export default state;