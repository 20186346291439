const getters = {
    stepperStateGetters: state => state.stepperState || [],
    currentActiveStepperGetters: state => state.stepperState.filter(el => el.active),
    paymentDetailsGetter: state => state.paymentDetails,
    userDetailsGetter: state => state.userDetails,
    paymentRequestGetter: state => state.paymentDetails.paymentRequestInfo,
    queryStringGetter: state => state.queryString,
    transferTypeGetter: state => state.transferType,

    //Getters for Trading TransCrypt
    tradingBuyInfoGetter: state => state.tradingDetails,
    userFiatBalanceGetter: state => state.userDetails.trading.userTradingBalances.filter(item => item.bal_type === "fiat"),
    userCryptoBalanceGetter: state => state.userDetails.trading.userTradingBalances.filter(item => item.bal_type === "crypto").sort((a,b) => (a.ccy < b.ccy) ? -1 : (a.ccy > b.ccy)  ? 1 : 0),
    userVoucherBalanceGetter : state => state.userDetails.trading.userTradingBalances.filter(item => item.bal_type === "voucher"),
    selfRecipientsGetter : state => state.userDetails.recipients.filter(item => item.receiver_type === 'self'),
    userTransactionHistoryGetter : state => state.userDetails.trading.userTransactionHistory,
    userWithdrawBankAccountDetailGetter : state => state.userDetails.trading.userWithdrawBankAccountDetail,
    priceFeedByHourGetter : state => state.tradingPriceData.hour,
    priceFeedByDayGetter : state => state.tradingPriceData.day,
    priceFeedByWeekGetter: state => state.tradingPriceData.week,
    priceFeedByMonthGetter : state => state.tradingPriceData.month,
    priceFeedByYearGetter : state => state.tradingPriceData.year,
    priceFeedBTCGetter : state => state.newTradingPriceData['btc'],
    priceFeedETHGetter : state => state.newTradingPriceData['eth'],
    priceFeedUSDTGetter : state => state.newTradingPriceData['usdt'],
    priceFeedUSDCGetter : state => state.newTradingPriceData['usdc'],
    getUserKyc: state => state.userKyc,
    getUserStatus: state => state.userStatus
}

export default getters;