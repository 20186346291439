import Vue from 'vue';

let debugConsole = false;

const mutations = {
    INITIATE_STEPPER(state, val) {
        state.stepperState = val;
    },

    UPDATE_STEPPER(state, val) {
        //val will be an object with keys of 'stepname','index',active,done
        //Stepname will be : 'remittanceInfo', 'senderInfo', recipientInfo, 'reviewInfo', 'paymentInfo'
        for (let i = 0; i < (state.stepperState || []).length; i++) {
            if (state.stepperState[i].stepName === val) {
                if (!state.stepperState[i].active && !state.stepperState[i].done) {
                    Vue.set(state.stepperState, i, {...state.stepperState[i], active: true, done : true})
                }
                else if(!state.stepperState[i].active && state.stepperState[i].done)
                {
                    Vue.set(state.stepperState, i, {...state.stepperState[i], active: true, done : true})
                    for (let n = i+1 ; n < state.stepperState.length; n++)
                    {
                        Vue.set(state.stepperState, n, {...state.stepperState[n], active:false, done:false})
                    }
                }
            } else {
                state.stepperState[i].active = false;
            }
        }
    },

    REGISTER_USER(state,val)
    {
        state.userDetails.email = val.email;
        state.userDetails.profile.senderCountry = val.country;
    },

    USER_LOGIN(state,val){
        state.userDetails.email = val.email;
        state.userDetails.userToken = val.token;
        state.userDetails.userKey  = val.userKey;

    },
    UPDATE_FIAT_EXCHANGE_RATE(state, val){
        state.fiatExchangeRate = val;
    },
    UPDATE_CRYPTO_EXCHANGE_RATE(state, val)
    {
        state.cryptoExchangeRate = val;
    },
    UPDATE_USER_PROFILE(state,val)
    {
      if(debugConsole)  console.log("Update user Profile");
        // state.userDetails.profile = val;
        for(let i in val)
        {
            state.userDetails.profile[i] = val[i]
        }
    },
    UPDATE_USER_BANK_DETAILS(state,val)
    {
        state.userDetails.bankDetails = val;
        // state.paymentDetails.recipientInfo.recipientBankDetails=val;
    },
    UPDATE_RECIPIENT_PROFILE_AND_BANK_DETAILS(state,val)
    {
        state.userDetails.recipients.push(val);
    },
    UPDATE_REMITTANCE_INFO(state,val)
    {
        state.paymentDetails.remittanceInfo = val;
    },
    UPDATE_RECIPIENT_DETAILS(state,val)
    {
        state.paymentDetails.recipientInfo = val;
    },
    UPDATE_TRANSACTION(state,val)
    {
        state.userDetails.transactions.push(val);
    },
    GET_RECIPIENTS(state,val)
    {
      state.userDetails.recipients = val;
      for(let i = 0; i < state.userDetails.recipients.length; i++)
      {
          Vue.set(state.userDetails.recipients[i],"visible",false);
      }
    },
    USER_LOGOUT(state)
    {
        if(debugConsole) console.log("User logout");
        state.paymentDetails = {
            remittanceInfo : {},
            recipientInfo :{},
        };
        state.userDetails =  {
            userToken: "",
            userKey:"",
            email : "",
            profile : {
                senderFirstAndMiddleName : "",
                senderLastName: "",
                senderDateOfBirth : "",
                senderPhone : "",
                senderCountry : "",
                senderCity : "",
                senderAddress : "",
                senderPostalCode : "",
            },
            bankDetails : {

            },
            transactions : [],
            recipients : [],
            poi : {},
            trading : {
                userTransactionHistory : [],
                userBankAccountDetails: [],
                userTradingProfile : [],
                userTradingBalances : [],
                userWithdrawBankAccountDetail : {},




            }

        };
        state.paymentDetails.remittanceInfo = {};
        state.paymentDetails.recipientInfo = {};

        //remove state for trading
        state.tradingDetails.buyInfo = {};
        state.tradingDetails.sellInfo = {};
        state.tradingData = {};
        state.newTradingPriceData = {
            'btc': {
                hour: [],
                day: [],
                week: [],
                month: [],
                year: []

            },
            'eth':{
                hour: [],
                day: [],
                week: [],
                month: [],
                year: []

            },
            'usdt':{
                hour: [],
                day: [],
                week: [],
                month: [],
                year: []
            },
            'usdc':{
                hour: [],
                day: [],
                week: [],
                month: [],
                year: []
            }
        }
    },
    USER_TOKEN_EXPIRED(state)
    {
        state.userDetails.userToken = "";
    },
    UPDATE_PAYMENT_REQUEST(state,val)
    {
        state.paymentDetails.paymentRequestInfo = val;
    },
    EXPAND_RECIPIENT(state,val)
    {
        let findRecipient = state.userDetails.recipients.find(el=> el.receiver_key === val.receiverKey)
        findRecipient.visible = val.visible;

    },
    UPDATE_TRANSACTIONS(state,val)
    {
        if(debugConsole) console.log("Val", val)
        let sortedVal = val.sort(function(a,b) {
            return (a.tx.status_dt > b.tx.status_dt) ? -1 : ((a.tx.status_dt < b.tx.status_dt) ? 1 : 0);
        });
        if(debugConsole)  console.log("After sorted", sortedVal);
        state.userDetails.transactions = sortedVal;
        for(let i = 0; i < state.userDetails.transactions.length; i++)
        {
            Vue.set(state.userDetails.transactions[i],"visible",false);
        }

    },
    EXPAND_TRANSACTION(state, val)
    {
        let findTransaction = state.userDetails.transactions.find(el => el.tx.tx_ref_num === val.txReference);
        findTransaction.visible = val.visible;
    },
    UPDATE_QUERY_STRING(state,val)
    {
        state.queryString = val;
    },
    REMOVE_QUERY_STRING (state)
    {
        state.queryString = null;
    },
    UPDATE_TRANSFER_TYPE(state,val)
    {
        state.transferType = val;
    },

    // Mutations for Trading
    UPDATE_TRADING_BUY(state,val)
    {
      state.tradingDetails.buyInfo = val;
    },
    UPDATE_USER_BALANCES(state,val)
    {
        state.userDetails.trading.userTradingBalances = val;
    },
    UPDATE_USER_TRANSACTION_HISTORY(state,val)
    {
        state.userDetails.trading.userTransactionHistory = val;
    },
    UPDATE_USER_WITHDRAW_TO_BANK_ACCOUNT_DETAIL(state, val)
    {
        state.userDetails.trading.userWithdrawBankAccountDetail = val;
    },
    UPDATE_KYC_URL(state, val)
    {
        state.userDetails.trading.kycUrl = val;
    },
    UPDATE_PRICE_FEED(state, val){
        let duration = val.duration;
        for(let i in state.tradingPriceData){
            if(i === duration)
            {
                state.tradingPriceData[i] = val.result;
            }
        }

    },
    UPDATE_NEW_PRICE_FEED(state, val){
        let crypto = val.crypto;
        let duration = val.duration;

        // console.log("UPDATE NEW PRICE FEED val crypto", crypto);
        // console.log("UPDATE NEW PRICE FEED val duration", duration);
        //
        for (let i in state.newTradingPriceData)
        {
            if( i === crypto)
            {
                state.newTradingPriceData[i][duration] = val.result;
            }
        }
    },
    UPDATE_USER_KYC(state, value = "") {
        state.userKyc = value;
    },
    UPDATE_USER_STATUS(state, value = "") {
        state.userStatus = value;
    }

}

export default mutations;